/**
 * @file
 * Bootstrap Accordion behaviors.
 */
(function ($) {
  // Change indicator behaviors for bootstrap panel accordions.
  Drupal.behaviors.accordionIndicator = {
    attach: function (context, settings) {
      $('.panel', context).once('accordian-indicator', function () {
        $('.panel').on('show.bs.collapse', function(e) {
          $(e.target).prev('.panel-heading').addClass('active');
          $(e.target).prev('.panel-heading').find('.accordion-toggle-indicator').addClass('fa-minus').removeClass('fa-plus');
        });

        $('.panel').on('hide.bs.collapse', function(e) {
          $(e.target).prev('.panel-heading').removeClass('active');
          $(e.target).prev('.panel-heading').find('.accordion-toggle-indicator').addClass('fa-plus').removeClass('fa-minus');
        });
      });
    }
  };
})(jQuery);